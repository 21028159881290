import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import {
  Section,
  Container,
  SectionTitle,
  SectionDescription,
  SectionLineDescription,
} from "../components/Section";
import { ArrowLeftIcon } from "../components/Icons";
import { HeroBanner, HeroImage } from "../components/HeroBanner";
import ProductCarousel from "../components/ProductCarousel";
import QuotePopUpButton from "../components/QuotePopUpButton";
import AboutBanner from "../images/about-banner.jpg";

const SectionCustom = styled(Section)`
  overflow: hidden;
  .container {
    @media (min-width: 1410px) {
      max-width: inherit;
      padding-right: 0;
      padding-left: 0;
    }
  }
`;
const Grid = styled.div`
  .grid-item {
    position: relative;
    @media (min-width: 1410px) {
      padding-left: calc((100% - 1350px) / 2);
      padding-right: 0;
    }
    &:nth-child(even) {
      flex-direction: row-reverse;
      @media (min-width: 1410px) {
        padding-right: calc((100% - 1350px) / 2);
        padding-left: 0;
      }
      .grid-figure {
        padding: 20px;
        @media (min-width: 992px) {
          border-radius: 0 230px 230px 0;
        }
        @media (min-width: 1600px) {
          padding: 40px;
        }
      }
      .grid-content {
        &:after {
          right: 0;
          left: auto;
          clip-path: polygon(0px 0px, 100% 100%, 100% 0);
        }
      }
    }
    + .grid-item {
      margin-top: 40px;
      @media (min-width: 768px) {
        margin-top: 60px;
      }
      @media (min-width: 992px) {
        margin-top: 80px;
      }
      @media (min-width: 1200px) {
        margin-top: 100px;
      }
      @media (min-width: 1600px) {
        margin-top: 120px;
      }
    }
  }
`;
const GridItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -20px;
  @media (min-width: 992px) {
    margin: 0 -30px;
  }
`;
const GridItemCol = styled.div`
  position: relative;
  width: 100%;
  padding: 0 20px;
  @media (min-width: 992px) {
    padding: 0 30px;
    flex: 0 0 50%;
    max-width: 50%;
  }
  &.grid-content {
    &:after {
      @media (min-width: 1200px) {
        content: "";
        position: absolute;
        left: 0;
        width: 28px;
        height: 28px;
        background-color: #373636;
        clip-path: polygon(0px 0px, 100% 0, 0 100%);
        top: -40px;
      }
      @media (min-width: 1600px) {
        top: -60px;
      }
    }
  }
`;

const Figure = styled.div`
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(87, 107, 228, 0.1);
  padding: 20px;
  @media (min-width: 992px) {
    border-radius: 230px 0 0 230px;
  }
  @media (min-width: 1600px) {
    padding: 40px;
  }
  .gatsby-image-wrapper {
    display: inline-block;
    position: relative;
    z-index: 1;
  }
`;
const Explore = styled.div`
  text-align: center;
`;

const AboutUsPage = ({ location, data }) => {
  const breadcrumbSchema = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home Page",
        item: "https://www.engineeredmetalbuildings.com/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "About Us",
        item: `https://www.engineeredmetalbuildings.com/about-us/`,
      },
    ],
  };
  const commonSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    image:
      "https://www.engineeredmetalbuildings.com/static/b81f2bb5f87a3992879586deaa81d02f/88ed0/logo.webp",
    url: "https://www.engineeredmetalbuildings.com/",
    sameAs: [
      "https://maps.app.goo.gl/yabxaKrf3LRjxD9F8",
      "https://www.embmetalbuildings.com",
      "https://www.engineeredmetalbuildings.com/idaho/",
    ],
    logo: "https://www.engineeredmetalbuildings.com/static/b81f2bb5f87a3992879586deaa81d02f/88ed0/logo.webp",
    name: "EMB Metal Buildings, LLC",
    description:
      "At EMB Metal Buildings, we provide a wide range of strong, durable, and affordable structures to meet all your storage needs. Order your building today!",
    email: "sales@embmetalbuildings.com",
    telephone: "(208) 572-1441",
    address: {
      "@type": "PostalAddress",
      streetAddress: "340 Centennial Drive",
      addressLocality: "Heyburn",
      addressRegion: "ID",
      postalCode: "83336",
      addressCountry: "US",
    },
  };

  const schema = [breadcrumbSchema, commonSchema];
  return (
    <Layout location={location}>
      <Seo
        title="Trusted Metal Building Manufacturer | EMB Metal Buildings"
        description="EMB Metal Buildings provide reliable and affordable steel structures. All our buildings are customizable according to your needs. Shape your future with our durable buildings!"
        schemaMarkup={schema}
      />
      <HeroBanner>
        <HeroImage bgImg={`url(${AboutBanner})`}></HeroImage>
      </HeroBanner>
      <Section
        ept="60px"
        epb="60px"
        xpt="40px"
        xpb="40px"
        pt="30px"
        pb="30px"
        bgColor="#fff"
        className="section-about"
      >
        <SectionLineDescription mb="0" textAlign="center">
          <Container>
            <h1>
              EMB Metal Buildings - Where Your Vision Meets Our Expertise{" "}
            </h1>
            <p>
              Imagine a place where durability meets versatility, where quality
              is the rule, not the exception, and where your vision comes to
              life - EMB Metal Buildings, the leading metal building
              manufacturer!
            </p>
            <p>
              Rooted in American soil, we are more than a company; we're a
              community of innovators, creators, and visionaries committed to
              redefining the standards of the metal building industry. Our
              strong, smart, and customizable steel structures are our pride.
              When you purchase a building from us, you become a part of a story
              that celebrates ideas, values relationships and strives for
              perfection. We have been delivering and installing buildings and
              trust for years now. Join us, and together, let's shape a future
              that's as sturdy, versatile, and exceptional as the buildings we
              create.{" "}
            </p>
            <Explore>
              <QuotePopUpButton
                text="Get Quote"
                iconAfter={<ArrowLeftIcon />}
                thanksURL="/thank-you-about-us"
              />
            </Explore>
          </Container>
        </SectionLineDescription>
      </Section>

      <SectionCustom
        ept="60px"
        epb="60px"
        xpt="40px"
        xpb="40px"
        pt="30px"
        pb="30px"
        bgColor="#fff"
        className="section-custom"
      >
        <Container className="container">
          <Grid className="grid">
            <GridItem className="grid-item">
              <GridItemCol className="grid-content">
                <SectionTitle>
                  Our Mission - Building Quality with Every Structure{" "}
                </SectionTitle>
                <SectionDescription>
                  <hr />
                  <p>
                    At EMB Metal Buildings LLC, our mission is more than just
                    constructing metal buildings - it's about building trust and
                    exceeding expectations. We aim to offer our clients the most
                    durable, functional, and aesthetically pleasing metal
                    structures on the market. From the initial design process to
                    the final installation, we make it our goal to provide you
                    with a stress-free experience. We're not just building
                    structures; we're building relationships.{" "}
                  </p>
                </SectionDescription>
              </GridItemCol>
              <GridItemCol>
                <Figure className="grid-figure">
                  <StaticImage src="../images/mission.png" alt="mission" />
                </Figure>
              </GridItemCol>
            </GridItem>

            <GridItem className="grid-item">
              <GridItemCol className="grid-content">
                <SectionTitle>Our Team - Craftsmen of Excellence </SectionTitle>
                <SectionDescription>
                  <hr />
                  <p>
                    Our team is our greatest asset. We're proud to be home to a
                    group of talented professionals who share a common passion
                    for delivering the best in the industry. Each team member,
                    from our design consultants to our installation experts, is
                    dedicated to ensuring that every client's vision is realized
                    with utmost precision and care. Our people are not just
                    employees; they're the architects of your dream structures.{" "}
                  </p>
                </SectionDescription>
              </GridItemCol>
              <GridItemCol>
                <Figure className="grid-figure">
                  <StaticImage src="../images/our-team.png" alt="our-team" />
                </Figure>
              </GridItemCol>
            </GridItem>

            <GridItem className="grid-item">
              <GridItemCol className="grid-content">
                <SectionTitle>Get the Best from the Best!</SectionTitle>
                <SectionDescription>
                  <hr />
                  <p>
                    At EMB Metal Buildings LLC, our top priority is providing
                    our customers with the highest quality structures. Our
                    buildings are expertly designed and constructed to ensure
                    they will last for many years. Don't settle for a wooden or
                    stick-built structure when you can get the best from us. Get
                    a reliable and durable steel building from EMB Metal
                    Buildings LLC today!{" "}
                  </p>
                </SectionDescription>
              </GridItemCol>
              <GridItemCol>
                <Figure className="grid-figure">
                  <StaticImage src="../images/get-best.png" alt="get-best" />
                </Figure>
              </GridItemCol>
            </GridItem>
          </Grid>
        </Container>
      </SectionCustom>
      <ProductCarousel data={data.allContentfulProduct.edges} />
    </Layout>
  );
};
export default AboutUsPage;

export const pageQuery = graphql`
  query AboutUsPagePageQuery {
    allContentfulProduct(sort: { fields: skuNumber, order: ASC }) {
      edges {
        node {
          id
          url
          rto
          financingAvailable
          skuNumber
          title
          image {
            gatsbyImageData(quality: 50, placeholder: BLURRED)
            fluid {
              srcWebp
            }
          }
          price
          width
          length
          height
          city
          state
          zipcode
          location {
            lat
            lon
          }
          buildingCondition
          description {
            childMarkdownRemark {
              html
            }
          }
          specifications {
            childMarkdownRemark {
              html
            }
          }
          productCategories {
            title
          }
          buildingTags
          roofStyleType
          manufacturer
          gaugeTubing
          gaugePanels
        }
      }
    }
  }
`;
